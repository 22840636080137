<template>
  <div class="speaking-by-turntable-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <Star
        :totalStarNumber="optionList[0].topDis.length - 1"
        :currentIndex="starNum"
      />

      <div class="backGroundImg-area">
        <img :src="bgImg" alt="" />
      </div>
      <div class="card-area">
        <div class="card-wrapper">
          <div
            class="card-item"
            v-for="(item, index) in optionList"
            :key="index"
          >
            <img
              :src="item.bgImg"
              class="swiper-img"
              :class="{
                swiperAnimationLeft: isMoveSwiperLeft && index === 0,
                swiperAnimationMiddle: isMoveSwiperMiddle && index === 1,
                swiperAnimationRight: isMoveSwiperRight && index === 2,
              }"
              :style="{ top: item.topDis[currentIndex] + '%' }"
            />
          </div>
        </div>
      </div>
      <div class="click-area">
        <div class="click-button" @click="handleClickTiger"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "../Star";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "TigerMachineGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  components: {
    PageButton,
    Star,
  },
  data() {
    return {
      currentIndex: 0,
      starNum: 0,
      isLastStep: false,
      clickSocketInfo: {},

      isMoveSwiperRight: false,
      isMoveSwiperLeft: false,
      isMoveSwiperMiddle: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20042,
          data: { value },
          text: "SpeakingByTurntableGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("ClickTigerMachinde", () => {
      this.handleClickTiger("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("ClickTigerMachinde");
  },

  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickTiger(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          value: Math.random(),
        };
      }
      if (this.currentIndex === this.optionList[0].topDis.length - 1) {
        return;
      }
      this.isMoveSwiperLeft = true;
      this.isMoveSwiperMiddle = true;
      this.isMoveSwiperRight = true;
      setTimeout(() => {
        this.isMoveSwiperLeft = false;
        this.currentIndex++;
      }, 1500);
      setTimeout(() => {
        this.isMoveSwiperMiddle = false;
      }, 2500);
      setTimeout(() => {
        this.isMoveSwiperRight = false;
        this.starNum++;
        if (this.currentIndex >= this.optionList[0].topDis.length - 1) {
          startConfetti();
          playCorrectSound();
          this.isLastStep = true;
        } else {
          playCorrectSound(true, false);
        }
      }, 3500);
    },
  },
};
</script>

<style lang="scss" scoped>
.speaking-by-turntable-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .backGroundImg-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .card-wrapper {
        position: absolute;
        width: 38%;
        height: 18%;
        left: 24%;
        top: 37%;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 1400px) {
          width: 38%;
          height: 16%;
          left: 24%;
          top: 38%;
        }
        @media screen and (max-width: 1300px) {
          width: 38%;
          height: 16%;
          left: 24%;
          top: 38%;
        }
        @media screen and (max-width: 1200px) {
          width: 43.5%;
          height: 16%;
          left: 19.8%;
          top: 38%;
        }
        @media screen and (max-width: 1100px) {
          width: 46%;
          height: 16%;
          left: 18%;
          top: 38.5%;
        }
        @media screen and (max-width: 1000px) {
          width: 48%;
          height: 15%;
          left: 16%;
          top: 39%;
        }

        // background: #000;
        // opacity: 0.2;
        .card-item {
          //   background: #000;
          width: 30%;
          overflow: hidden;
          position: relative;
          border-radius: 20px;

          .swiper-img {
            border-radius: 20px;
            position: absolute;
            margin: auto;
            width: 100%;
            top: 0;
          }

          .swiperAnimationLeft {
            animation: swiperAnimate 0.5s linear infinite;
          }
          .swiperAnimationMiddle {
            animation: swiperAnimate 0.5s linear infinite 0.5s;
          }
          .swiperAnimationRight {
            animation: swiperAnimate 0.5s linear infinite 1s;
          }
        }
      }
    }
    .click-area {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .click-button {
        position: absolute;
        width: 5%;
        height: 20%;
        // background: #000;
        top: 27%;
        right: 24%;
        cursor: pointer;
      }
    }
  }
}
@keyframes swiperAnimate {
  0% {
    top: -240%;
  }
  100% {
    top: 0;
  }
}
</style>
