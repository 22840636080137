var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "speaking-by-turntable-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.optionList[0].topDis.length - 1,
              currentIndex: _vm.starNum,
            },
          }),
          _c("div", { staticClass: "backGroundImg-area" }, [
            _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
          ]),
          _c("div", { staticClass: "card-area" }, [
            _c(
              "div",
              { staticClass: "card-wrapper" },
              _vm._l(_vm.optionList, function (item, index) {
                return _c("div", { key: index, staticClass: "card-item" }, [
                  _c("img", {
                    staticClass: "swiper-img",
                    class: {
                      swiperAnimationLeft: _vm.isMoveSwiperLeft && index === 0,
                      swiperAnimationMiddle:
                        _vm.isMoveSwiperMiddle && index === 1,
                      swiperAnimationRight:
                        _vm.isMoveSwiperRight && index === 2,
                    },
                    style: { top: item.topDis[_vm.currentIndex] + "%" },
                    attrs: { src: item.bgImg },
                  }),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "click-area" }, [
            _c("div", {
              staticClass: "click-button",
              on: { click: _vm.handleClickTiger },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }